<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <path
      d="M15 17.5048C15 16.1206 13.8794 15 12.5 15h-1.25c-2.76125 0-5 2.2424-5 5.0098v3.7304c0 2.7674 2.23875 5.0098 5 5.0098h1.25c1.3794 0 2.5-1.1218 2.5-2.5048v-8.7404zM20 0C8.82078 0 .356406 9.30664 0 20v3.125C0 24.1605.839453 25 1.875 25c1.03555 0 1.875-.8395 1.875-1.875V20c0-8.9587 7.2925-16.23414 16.25-16.23531C28.9575 3.76586 36.25 11.0413 36.25 20v11.25c0 1.7259-1.3991 3.125-3.125 3.125H24.48c-.6496-1.1163-1.8454-1.875-3.23-1.875h-2.3611c-1.8134 0-3.4759 1.2262-3.8175 3.0072C14.6116 37.904 16.436 40 18.75 40h2.5c1.3846 0 2.5804-.7587 3.23-1.875h8.645c3.797 0 6.875-3.078 6.875-6.875V20C39.6436 9.30664 31.1792 0 20 0zm8.75 28.75c2.7612 0 5-2.2424 5-5.0098v-3.7304c0-2.7674-2.2388-5.0098-5-5.0098H27.5c-1.3794 0-2.5 1.1218-2.5 2.5048v8.7404c0 1.3842 1.1206 2.5048 2.5 2.5048h1.25z"
      fill="url(#sav-gradiant)"
    />
    <defs>
      <linearGradient id="sav-gradiant" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFCB00" />
        <stop offset="1" stop-color="#FFDB9B" />
        <stop offset="1" stop-color="#FFF5CF" />
      </linearGradient>
    </defs>
  </svg>
</template>
