<template>
  <div class="carousel">
    <div class="content flex-col" :class="[currentSlide.className, { 'with-clouds': withCloudsContent }]">
      <div class="img-container flex-row v-bot h-center" v-if="currentSlide.img">
        <img
          class="img"
          :src="currentSlide.img"
          :alt="currentSlide.title"
          width="100%"
          height="100%"
          loading="lazy"
        />
      </div>
      <div class="text-container" :class="{ 'with-image': !!currentSlide.img }">
        <div class="title">
          {{ currentSlide.title }}
        </div>
        <div
          v-if="currentSlide.desktopSubtitle"
          class="subtitle hide-on-mobile"
          v-html="currentSlide.desktopSubtitle"
        ></div>
        <div
          class="subtitle"
          v-if="currentSlide.subtitle"
          v-html="currentSlide.subtitle"
          :class="{ 'hide-on-desktop': currentSlide.desktopSubtitle }"
        ></div>
      </div>
    </div>
    <div class="navigation flex-row">
      <div
        class="nav-card flex-col flex-center"
        :class="[nav.className, { 'is-active': currentIndex === $index }]"
        v-for="(nav, $index) in navElements"
        :key="$index"
        @click="setSlide($index)"
      >
        <img
          v-if="nav.isImg"
          class="img"
          :src="nav.img"
          :alt="nav.title"
          width="100%"
          height="100%"
          loading="lazy"
        />
        <component v-else class="icon" :alt="nav.title" :is="nav.img" />
        <h3 class="title" v-html="nav.title"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentNav() {
      return this.data[this.currentIndex].nav
    },
    currentSlide() {
      return this.data[this.currentIndex].content
    },
    navElements() {
      return this.data.map((d) => d.nav)
    },
  },
  data() {
    return {
      currentIndex: 0,
    }
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    withCloudsContent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setSlide(index) {
      this.currentIndex = index
    },
  },
}
</script>

<style lang="less" scoped>
.carousel {
  position: relative;
  width: 100%;

  .content {
    align-items: center;
    height: 360px;
    padding: 0 38px;
    transition: all 0.2s ease;

    .img {
      margin-left: -25px;
      margin-bottom: 12px;
      width: 111px;
      height: auto;
      transition-delay: 100ms;
    }

    .title,
    .subtitle {
      color: #272727;
      text-align: center;
    }

    .title {
      margin-bottom: 36px;
      font-weight: 500;
      font-size: 20px;
    }
    .subtitle {
      font-weight: 500;
      font-size: 16px;
    }
  }

  .navigation {
    position: relative;
    top: -40px;
    width: 100%;
    padding: 0 12px;
    justify-content: center;

    .generate-delay(6);
    .generate-delay(@n, @i: 1) when (@i =< @n) {
      .nav-card:nth-child(@{i}) {
        transition-delay: ((@i - 1) * 90ms);
      }
      .generate-delay(@n, (@i + 1));
    }

    .nav-card {
      position: relative;
      padding: 10px 10px 0;
      min-width: 92px;
      background-color: #fff;
      border: 1px solid #F8F9FA;
      border-radius: 20px;
      height: 88px;
      box-shadow: 0 0 22px rgba(0, 0, 0, 0.07);
      cursor: pointer;
      transition: all 0.2s ease;

      &.is-active .title {
        font-weight: var(--ds-weight-semi-bold);
      }

      &:not(:last-child) {
        margin-right: 9px;
      }

      .icon {
        width: 18px;
        height: 18px;
        margin-bottom: 5px;
      }

      .title {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #272727;
        margin-bottom: 9px;
        margin-top: 0;
      }

      &:after {
        display: block;
        content: '';
        border-bottom: 5px solid transparent;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        position: absolute;
        bottom: 0;
        left: 15px;
        right: 15px;
      }

      &.is-active {
        &.sav {
          box-shadow: inset 0px 0px 0px 2px #ffda6c, 0px 0px 22px rgba(0, 0, 0, 0.07);
        }

        &.platform {
          box-shadow: inset 0px 0px 0px 2px var(--ds-color-primary-100), 0px 0px 22px rgba(0, 0, 0, 0.07);
        }

        &.heart {
          box-shadow: inset 0px 0px 0px 2px #f17592, 0px 0px 22px rgba(0, 0, 0, 0.07);
        }

        @media @bp-mobile {
          &.sav,
          &.platform,
          &.heart {
            box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.07);
          }

          :deep(.icon) {
            fill: var(--ds-color-primary-100) !important;

            path {
              fill: var(--ds-color-primary-100) !important;
            }
          }
        }

        .title {
          color: var(--ds-color-primary-100);
        }

        &:after {
          border-bottom-color: var(--ds-color-primary-100);
        }
      }
    }
  }

  @media @bp-desktop {
    position: unset;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    .navigation {
      position: unset;
      margin: 0 32px 0 0;
      width: 353px;
      flex-direction: column;
      justify-content: unset;

      .nav-card {
        padding: 0 42px;
        height: 100px;
        flex-direction: row;
        justify-content: flex-start;

        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 24px;
        }

        .icon {
          margin-bottom: 0;
        }

        .title {
          margin-bottom: 0;
          text-align: left;
        }

        &.is-active {
          .title {
            color: #272727;
            font-weight: 500;
          }

          &:after {
            content: none;
          }
        }

        .title {
          margin-left: 15px;
          font-size: 20px;
        }
      }
    }
    .content {
      position: relative;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;
      max-width: 855px;
      height: unset;
      padding: 0 78px;
      border-radius: 12px;
      padding-right: 109px;

      &.with-clouds:after {
        content: '';
        width: 100%;
        height: 160px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: url('~/assets/imgs/arg-clouds.webp') no-repeat;
        background-size: cover;
        filter: invert(100%);
        mix-blend-mode: soft-light;
      }

      .text-container {
        flex-direction: column;

        &.with-image {
          margin-left: 50px;
        }

        div.title {
          margin-top: 0;
        }
      }

      .title {
        font-size: 24px;
        margin-bottom: 29px;
      }

      .subtitle {
        font-size: 18px;
      }

      &.bg-pink {
        .text-container.with-image {
          margin-left: 32px;
        }
        .img {
          margin-top: 30px;
          margin-left: 2px;
        }
      }

      &.bg-yellow .img,
      &.bg-purple .img {
        width: auto;
        height: 158px;
      }
    }
  }
}
</style>
