<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
    <g clip-path="url(#clip0)">
      <path
        d="M26.8164 38.4911c-1.6073 3.0474-3.8279 5.0613-3.9561 5.1761-.2448.2192-.5523.3286-.8598.3286-.3074 0-.6153-.1094-.86-.3286-.1282-.1148-2.3482-2.1287-3.9555-5.1761 1.8917-.7526 3.5268-1.7852 4.8155-2.7631 1.2891.9779 2.9239 2.0105 4.8159 2.7631z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M3.005 27.3276c.23969.2716.48609.5358.73987.7896 2.53281 2.5325 5.89411 4.3435 9.99123 5.3828 2.176.5519 4.1922.7866 5.7112.8809-2.0145 1.3461-4.7467 2.6419-7.8012 2.6419C5.423 37.0228.536316 31.645.331207 31.416c-.43808-.4894-.43808-1.2299 0-1.7194.067139-.0748 1.056763-1.1679 2.673793-2.369z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M30.2642 33.5004c4.0972-1.0393 7.4588-2.8504 9.9916-5.3832.2541-.2538.5002-.5177.7402-.7896 1.6167 1.2011 2.6063 2.2945 2.6735 2.369.4381.4895.4381 1.2303 0 1.7198-.2051.2289-5.0918 5.6064-11.3146 5.6064-3.0548 0-5.7866-1.2958-7.8015-2.6419 1.519-.0943 3.5352-.329 5.7108-.8805z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M22.8593 31.5166c-.2447.2192-.5522.329-.8597.329s-.615-.1098-.86-.329c-.3162-.283-7.7458-7.0341-7.7458-15.594 0-8.5595 7.4296-15.310631 7.7458-15.593956C21.3843.109436 21.6921 0 21.9996 0s.615.109436.8597.328644c.3166.283325 7.7462 7.034456 7.7462 15.593956s-7.4299 15.311-7.7462 15.594z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M10.8161 15.9224c0 2.271.4488 4.6014 1.3347 6.9257.6939 1.8215 1.6577 3.646 2.8648 5.4235.9205 1.3555 1.8517 2.4891 2.6211 3.3478-3.5493-.4588-8.50042-1.7563-12.06985-5.3254C-.48571 20.2415-.00600433 10.214.0174942 9.78998.0537491 9.13403.577431 8.61035 1.23338 8.5741c.05807-.00302.34207-.01746.80331-.01746 1.66974 0 5.66148.1853 9.66331 1.74356-.5872 1.89-.8839 3.7756-.8839 5.6222z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M26.3627 31.6198c.7694-.8591 1.7006-1.9927 2.6211-3.3482 1.2071-1.7775 2.1712-3.602 2.8651-5.4235.8856-2.3243 1.3347-4.6547 1.3347-6.9257 0-1.8466-.2967-3.7322-.8842-5.6222 4.0018-1.55826 7.9932-1.74356 9.6629-1.74356.462 0 .7459.0141.804.01746.656.03625 1.1797.55993 1.2159 1.21588.0235.42402.5032 10.45152-5.5493 16.50402-3.5698 3.5694-8.5209 4.8666-12.0702 5.3258z"
        fill="url(#paint5_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="22.0007"
        y1="35.728"
        x2="22.0007"
        y2="43.9958"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7442EC" />
        <stop offset="1" stop-color="#AD5CF6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="9.72496"
        y1="27.3276"
        x2="9.72496"
        y2="37.0228"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7442EC" />
        <stop offset="1" stop-color="#AD5CF6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="34.2757"
        y1="27.3276"
        x2="34.2757"
        y2="37.0228"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7442EC" />
        <stop offset="1" stop-color="#AD5CF6" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="21.9996"
        y1="0"
        x2="21.9996"
        y2="31.8456"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7442EC" />
        <stop offset="1" stop-color="#AD5CF6" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="8.81821"
        y1="8.55664"
        x2="8.81821"
        y2="31.6194"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7442EC" />
        <stop offset="1" stop-color="#AD5CF6" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="35.1813"
        y1="8.55664"
        x2="35.1813"
        y2="31.6198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7442EC" />
        <stop offset="1" stop-color="#AD5CF6" />
      </linearGradient>
      <clipPath id="clip0">
        <path fill="#fff" transform="matrix(-1 0 0 1 44 0)" d="M0 0h44v44H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
