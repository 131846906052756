<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 34">
    <path
      d="M35.0384 18.0751L20.703 32.8974c-.9383.9701-2.4737.9701-3.412 0L2.95569 18.0751c-4.15781-4.3024-3.928254-11.41726.68407-15.40956C7.66798-.821109 13.8322-.192105 17.5394 3.63506l1.4611 1.50842 1.4612-1.50842c3.707-3.827091 9.8711-4.456169 13.8993-.96974 4.6126 3.99222 4.8422 11.10728.6774 15.40978z"
      fill="url(#heat-gradiant)"
    />
    <defs>
      <linearGradient id="heat-gradiant" x1="19" y1=".375" x2="19" y2="33.625" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF003D" />
        <stop offset="1" stop-color="#FFA7BC" />
      </linearGradient>
    </defs>
  </svg>
</template>
