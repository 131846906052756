<template>
  <Section class="arguments flex-center flex-col">
    <h2 class="title">
      Pour que
      <strong>l'immobilier</strong>
      ne soit plus lourd et opaque
    </h2>
    <Carousel
      :data="args"
      withCloudsContent
    />
  </Section>
</template>

<script>
import IconsArgSav from '../Icons/ArgSav'
import IconsArgPlatform from '../Icons/ArgPlatform'
import IconsArgHeart from '../Icons/ArgHeart'

export default {
  data() {
    return {
      args: [
        {
          content: {
            className: 'bg-yellow',
            title: 'Nos équipes sont là pour vous aider à tout moment par chat, mail ou téléphone.',
          },
          nav: {
            className: 'sav',
            img: shallowRef(IconsArgSav),
            title: 'Un SAV <br class="hide-on-desktop"> en direct',
          },
        },
        {
          content: {
            className: 'bg-purple',
            title: 'Notre plateforme a été conçue pour être éducative et comprise par tous.',
          },
          nav: {
            className: 'platform',
            img: shallowRef(IconsArgPlatform),
            title: 'Une plateforme accessible à tous',
          },
        },
        {
          content: {
            className: 'bg-pink',
            title: 'Nous ne sommes ni une agence immobilière, ni des agents commerciaux.',
            subtitle: 'On vous conseille uniquement dans VOTRE intérêt.',
          },
          nav: {
            className: 'heart',
            img: shallowRef(IconsArgHeart),
            title: 'Pas de parti pris',
          },
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.arguments {
  .title {
    font-weight: var(--ds-weight-semi-bold);
    font-size: 24px;
    text-align: center;
    margin: 0 0 44px 0;
    width: 100%;
  }

  .l-o {
    height: auto;
    margin: 0 auto 36px auto;
  }

  .glyphs {
    width: 40px;
    height: 40px;
  }

  :deep(.carousel) {
    .navigation {
      .nav-card {
        &.sav {
          .icon {
            width: 40px;
            height: 40px;
          }
        }

        &.platform {
          .icon {
            width: 44px;
            height: 44px;
          }
        }

        &.heart {
          .icon {
            width: 38px;
            height: 34px;
          }
        }
      }
    }
    .content {
      .img-container {
        width: 111px;
        height: 117px;
      }
      &.bg-purple,
      &.bg-pink {
        .title,
        .subtitle {
          color: white;
        }
      }
    }
  }

  @media @bp-desktop {
    .title {
      font-size: 32px;
    }
    .l-o {
      width: 311px;
      height: auto;
    }

    :deep(.carousel) {
      .content {
        .img {
          margin-left: 0;
          width: 166px;
          height: 139px;
        }
      }
    }
  }

  @media @bp-md-down {
    :deep(.carousel) {
      .content {
        border-radius: unset;
        height: 300px;
        justify-content: center;
      }
    }
  }
}
</style>
